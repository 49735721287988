export default class ContractEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#contract_uuid") ? (elem.querySelector("#contract_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#contract_name") as HTMLInputElement).value,
            customer_id: (elem.querySelector("#contract_customer_id option:checked") as HTMLInputElement).value,
            valid_from: (elem.querySelector("#contract_valid_from") as HTMLInputElement).value,
            valid_to: (elem.querySelector("#contract_valid_to") as HTMLInputElement).value,
        }
    }
}